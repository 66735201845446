import * as React from "react";
import { Layout, Card, DefaultButton, Seo } from "../components";
import { navigate } from "gatsby";
import "../styles/screen.scss";
import { Box, Grid, GridItem, Heading, Flex, Text } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";

const seoBundle = {
  title: "American Storage",
  subtitle: "Our sizes",
  description: "Affordable pricing for portable storage units",
  slug: "/pricing",
};

const PricingPage = ({ location }) => {
  return (
    <Layout title={"Home"} location={location}>
      <Seo seoBundle={seoBundle} />
      <Box
        maxW='full'
        h='100%'
        // header takes up 40/50 pixels
        pt={["150px", null, "150px"]}
        px={["20px", null, "100px"]}
        pb={["60px", null, "100px"]}
        bg={"pink"}
      >
        <Heading
          as='h1'
          color='dkBlue'
          mb={["20px", null, null, "10px"]}
          mt='0px'
          fontWeight={800}
          fontSize={["3em", "4em"]}
          fontFamily='Lexend, sans-serif'
          lineHeight={["100%", null, null, "120%"]}
          textAlign='center'
        >
          All Storage Sizes & Capacity
        </Heading>
        <Flex
          mb='40px'
          mt='20px'
          flexDirection='row'
          justify='center'
          align='space-around'
        >
          <Flex
            flexDirection={["column", "row"]}
            justify='space-around'
            align='space-around'
          >
            <StaticImage
              src='../images/facility.jpg'
              alt='American Storage Facility'
              placeholder='blurred'
              layout='constrained'
              width={[300]}
            />
            <StaticImage
              src='../images/deliv.jpg'
              alt='American Storage Facility'
              placeholder='blurred'
              layout='constrained'
              width={[300]}
            />
            <StaticImage
              src='../images/delivery.jpg'
              alt='American Storage Facility'
              placeholder='blurred'
              layout='constrained'
              width={[300]}
            />
          </Flex>
        </Flex>
        <Grid
          templateColumns={["1fr", null, null, "repeat(3, 1fr)"]}
          templateRows={["1fr", null, null, "2fr"]}
          gap={20}
          mt='40px'
        >
          <GridItem colSpan={1}>
            <Flex h='100%' align='center'>
              <Card>
                <Flex flexDirection='column' justify='center' align='center'>
                  <StaticImage
                    src='../images/8x8.png'
                    alt='portable metal storage container'
                    placeholder='blurred'
                    layout='constrained'
                    width={200}
                  />
                  <Text
                    fontFamily='Outfit, sans-serif'
                    fontSize='20px'
                    fontWeight='bold'
                    mb='0'
                    color='dkBlue'
                  >
                    Small
                  </Text>
                  <Text
                    fontFamily='Outfit, sans-serif'
                    fontSize='16px'
                    fontWeight='bold'
                    color='dkBlue'
                  >
                    8 X 8
                  </Text>
                  {/* <Text
                    fontFamily='Outfit, sans-serif'
                    fontSize='24px'
                    fontWeight='bold'
                    mb='10px'
                  >
                    $45/mo
                  </Text> */}
                  <Text fontFamily='Outfit, sans-serif' mb='20px'>
                    <ul style={{ textAlign: "left", fontWeight: "bold" }}>
                      <li>8′ x 8′</li>
                      <li>1 1/2 rooms of furniture</li>
                      <li>
                        Fits refrigerators, sofas, washers, dryers, other large
                        furniture items.
                      </li>
                      <li>Covers storage for about a 500 square foot area</li>
                      <li>Weight Limit: 5,000 lbs</li>
                    </ul>
                  </Text>
                  <Text
                    fontFamily='Outfit, sans-serif'
                    fontSize='24px'
                    fontWeight='bold'
                    mb='10px'
                  >
                    Facility availability only
                  </Text>
                </Flex>
              </Card>
            </Flex>
          </GridItem>

          <GridItem colSpan={1}>
            <Flex h='100%' align='center'>
              <Card>
                <Flex flexDirection='column' justify='center' align='center'>
                  <StaticImage
                    src='../images/8x16.png'
                    alt='portable metal storage container'
                    placeholder='blurred'
                    layout='constrained'
                    width={200}
                  />
                  <Text
                    fontFamily='Outfit, sans-serif'
                    fontSize='20px'
                    fontWeight='bold'
                    mb='0'
                    color='dkBlue'
                  >
                    Portable Storage Unit*
                  </Text>
                  <Text
                    fontFamily='Outfit, sans-serif'
                    fontSize='16px'
                    fontWeight='bold'
                    color='dkBlue'
                  >
                    8 X 16
                  </Text>
                  {/* <Text
                    fontFamily='Outfit, sans-serif'
                    fontSize='24px'
                    fontWeight='bold'
                    mb='10px'
                  >
                    $100/mo + delivery/pickup*
                  </Text> */}
                  <Text fontFamily='Outfit, sans-serif' mb='20px'>
                    <ul style={{ textAlign: "left", fontWeight: "bold" }}>
                      <li>8′ x 16′ Portable Box</li>
                      <li>3 rooms of furniture</li>
                      <li>
                        Fits refrigerators, sofas, washers, dryers, other large
                        furniture items.
                      </li>
                      <li>Covers storage for about a 1,000 square foot area</li>
                      <li>Weight Limit: 7,000 lbs</li>
                    </ul>
                  </Text>
                  {/* <DefaultButton
                    onClick={() => {
                      navigate("/order?unit=8x16");
                    }}
                    title='Order Now'
                  /> */}
                  <Text
                    fontFamily='Outfit, sans-serif'
                    fontSize='24px'
                    fontWeight='bold'
                    mb='10px'
                  >
                    Store at our facility or yours
                  </Text>
                </Flex>
              </Card>
            </Flex>
          </GridItem>

          <GridItem colSpan={1}>
            <Flex h='100%' align='center'>
              <Card>
                <Flex flexDirection='column' justify='center' align='center'>
                  <StaticImage
                    src='../images/8x20.png'
                    alt='portable metal storage container'
                    placeholder='blurred'
                    layout='constrained'
                    width={200}
                  />
                  <Text
                    fontFamily='Outfit, sans-serif'
                    fontSize='20px'
                    fontWeight='bold'
                    mb='0'
                    color='dkBlue'
                  >
                    Large
                  </Text>
                  <Text
                    fontFamily='Outfit, sans-serif'
                    fontSize='16px'
                    fontWeight='bold'
                    color='dkBlue'
                  >
                    8 X 20
                  </Text>
                  {/* <Text
                    fontFamily='Outfit, sans-serif'
                    fontSize='24px'
                    fontWeight='bold'
                    mb='10px'
                  >
                    $75/mo
                  </Text> */}
                  <Text fontFamily='Outfit, sans-serif' mb='20px'>
                    <ul style={{ textAlign: "left", fontWeight: "bold" }}>
                      <li>8′ x 20′</li>
                      <li>5 rooms of furniture</li>
                      <li>
                        Fits refrigerators, sofas, washers, dryers, other large
                        furniture items.
                      </li>
                      <li>Covers storage for about a 1,500 square foot area</li>
                      <li>Weight Limit: 8,000 lbs</li>
                    </ul>
                  </Text>
                  <Text
                    fontFamily='Outfit, sans-serif'
                    fontSize='24px'
                    fontWeight='bold'
                    mb='10px'
                  >
                    Facility availability only
                  </Text>
                </Flex>
              </Card>
            </Flex>
          </GridItem>

          <GridItem colSpan={1}>
            <Flex h='100%' align='center'>
              <Card>
                <Flex flexDirection='column' justify='center' align='center'>
                  <StaticImage
                    src='../images/8x40.png'
                    alt='portable metal storage container'
                    placeholder='blurred'
                    layout='constrained'
                    width={200}
                  />
                  <Text
                    fontFamily='Outfit, sans-serif'
                    fontSize='20px'
                    fontWeight='bold'
                    mb='0'
                    color='dkBlue'
                  >
                    X-Large
                  </Text>
                  <Text
                    fontFamily='Outfit, sans-serif'
                    fontSize='16px'
                    fontWeight='bold'
                    color='dkBlue'
                  >
                    8 X 40
                  </Text>
                  {/* <Text
                    fontFamily='Outfit, sans-serif'
                    fontSize='24px'
                    fontWeight='bold'
                    mb='10px'
                  >
                    $145/mo
                  </Text> */}
                  <Text fontFamily='Outfit, sans-serif' mb='20px'>
                    <ul style={{ textAlign: "left", fontWeight: "bold" }}>
                      <li>8′ x 40′</li>
                      <li>About 8 - 10 rooms of furniture</li>
                      <li>
                        Fits refrigerators, sofas, washers, dryers, other large
                        furniture items.
                      </li>
                      <li>Covers storage for about a 2,500 square foot area</li>
                      <li>Weight Limit: 10,000 lbs</li>
                    </ul>
                  </Text>
                  <Text
                    fontFamily='Outfit, sans-serif'
                    fontSize='24px'
                    fontWeight='bold'
                    mb='10px'
                  >
                    Facility availability only
                  </Text>
                </Flex>
              </Card>
            </Flex>
          </GridItem>

          <GridItem colSpan={1}>
            <Flex h='100%' align='center'>
              <Card>
                <Flex flexDirection='column' justify='center' align='center'>
                  <StaticImage
                    src='../images/10x12.png'
                    alt='portable metal storage container'
                    placeholder='blurred'
                    layout='constrained'
                    width={200}
                  />
                  <Text
                    fontFamily='Outfit, sans-serif'
                    fontSize='20px'
                    fontWeight='bold'
                    mb='0'
                    color='dkBlue'
                  >
                    Extra Wide
                  </Text>
                  <Text
                    fontFamily='Outfit, sans-serif'
                    fontSize='16px'
                    fontWeight='bold'
                    color='dkBlue'
                  >
                    10 X 12
                  </Text>
                  {/* <Text
                    fontFamily='Outfit, sans-serif'
                    fontSize='24px'
                    fontWeight='bold'
                    mb='10px'
                  >
                    $55/mo
                  </Text> */}
                  <Text fontFamily='Outfit, sans-serif' mb='20px'>
                    <ul style={{ textAlign: "left", fontWeight: "bold" }}>
                      <li>10′ x 12′</li>
                      <li>3 or 4 rooms of furniture</li>
                      <li>
                        Fits refrigerators, sofas, washers, dryers, other large
                        furniture items.
                      </li>
                      <li>
                        Covers storage for about a 800-1,000 square foot area
                      </li>
                      <li>Weight Limit: 6,000 lbs</li>
                    </ul>
                  </Text>
                  <Text
                    fontFamily='Outfit, sans-serif'
                    fontSize='24px'
                    fontWeight='bold'
                    mb='10px'
                  >
                    Facility availability only
                  </Text>
                </Flex>
              </Card>
            </Flex>
          </GridItem>

          <GridItem colSpan={1}>
            <Flex h='100%' align='center'>
              <Card>
                <Flex flexDirection='column' justify='center' align='center'>
                  <StaticImage
                    src='../images/storage-bin.png'
                    alt='portable metal storage container'
                    placeholder='blurred'
                    layout='constrained'
                    width={200}
                  />
                  <Text
                    fontFamily='Outfit, sans-serif'
                    fontSize='20px'
                    fontWeight='bold'
                    mb='0'
                    color='dkBlue'
                  >
                    Corner Unit
                  </Text>
                  <Text
                    fontFamily='Outfit, sans-serif'
                    fontSize='16px'
                    fontWeight='bold'
                    color='dkBlue'
                  >
                    192 sq feet
                  </Text>
                  {/* <Text
                    fontFamily='Outfit, sans-serif'
                    fontSize='24px'
                    fontWeight='bold'
                    mb='10px'
                  >
                    $125/mo
                  </Text> */}
                  <Text fontFamily='Outfit, sans-serif' mb='20px'>
                    <ul style={{ textAlign: "left", fontWeight: "bold" }}>
                      <li>192 sq ft</li>
                      <li>4 1/2 rooms of furniture</li>
                      <li>
                        Fits refrigerators, sofas, washers, dryers, other large
                        furniture items.
                      </li>
                      <li>Covers storage for about a 1,500 square foot area</li>
                      <li>Weight Limit: 6,000 lbs</li>
                    </ul>
                  </Text>
                  {/* <DefaultButton
                    onClick={() => {
                      navigate("/order?unit=corner-unit");
                    }}
                    title='Coming Soon'
                    disabled
                  /> */}
                  <Text
                    fontFamily='Outfit, sans-serif'
                    fontSize='24px'
                    fontWeight='bold'
                    mb='10px'
                  >
                    Facility availability only
                  </Text>
                </Flex>
              </Card>
            </Flex>
          </GridItem>
        </Grid>
        {/* <Flex pt='40px' h='100%' align='center'> */}
        {/* <Card>
            <Flex flexDirection='column' justify='center' align='center'>
              <Text
                fontFamily='Outfit, sans-serif'
                fontSize='30px'
                fontWeight='bold'
                mb='5px'
                color='dkBlue'
              >
                *Delivery or Pickup
              </Text>
              <Text
                fontFamily='Outfit, sans-serif'
                fontSize='16px'
                fontWeight='bold'
                color='dkBlue'
              >
                (Within 30 mile radius)
              </Text> */}
        {/* <Text
                fontFamily='Outfit, sans-serif'
                fontSize='24px'
                fontWeight='bold'
                mb='10px'
              >
                $100
              </Text> */}
        {/* <Text
                fontFamily='Outfit, sans-serif'
                fontSize='16px'
                fontWeight='bold'
                mb='10px'
                color='dkBlue'
              >
                $3/mile (after 30 mile radius)
              </Text>
            </Flex>
            <Flex flexDirection='column' justify='center' align='center'>
              <Text
                fontFamily='Outfit, sans-serif'
                fontSize='30px'
                fontWeight='bold'
                mb='5px'
                mt='10px'
                color='dkBlue'
              >
                *Loaded Delivery or Pickup
              </Text>
              <Text
                fontFamily='Outfit, sans-serif'
                fontSize='16px'
                fontWeight='bold'
                color='dkBlue'
              >
                (Within 30 mile radius)
              </Text> */}
        {/* <Text
                fontFamily='Outfit, sans-serif'
                fontSize='24px'
                fontWeight='bold'
                mb='10px'
              >
                $125
              </Text> */}
        {/* <Text
                fontFamily='Outfit, sans-serif'
                fontSize='16px'
                fontWeight='bold'
                mb='10px'
                color='dkBlue'
              >
                $3/mile (after 30 mile radius)
              </Text>
            </Flex>
          </Card> */}
        {/* </Flex> */}
      </Box>
    </Layout>
  );
};

export default PricingPage;
